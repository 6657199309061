import React, { Component } from "react"

import { About } from "../../config/assets"

class ContactForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      rnName: "",
      rnEmail: "",
      rnSubject: "",
      rnMessage: "",
    }
  }

  isValid = () => {
    const { rnName, rnEmail, rnSubject, rnMessage } = this.state
    if (!rnName || !rnEmail || !rnSubject || !rnMessage) return false

    return true
  }

  render() {
    return (
      <div className="contact-form--1">
        <div className="container">
          <div className="row row--35 align-items-start">
            <div className="col-lg-6 order-2 order-lg-1">
              <div className="section-title text-left mb--50">
                <h2 className="title">Contact Us.</h2>
                <p className="description">
                  To drop us your questions, please fill this simple form and
                  we'll be in touch with you shortly
                </p>
              </div>
              <div className="form-wrapper">
                <form
                  name="website-query"
                  method="POST"
                  netlify-honeypot="bot-field"
                  data-netlify="true"
                >
                  <input type="hidden" name="bot-field" />
                  <input type="hidden" name="website-query" value="contact" />

                  <label htmlFor="item01">
                    <input
                      type="text"
                      name="name"
                      id="item01"
                      value={this.state.rnName}
                      onChange={e => {
                        this.setState({ rnName: e.target.value })
                      }}
                      placeholder="Your Name *"
                      required
                    />
                  </label>

                  <label htmlFor="item02">
                    <input
                      type="email"
                      name="email"
                      id="item02"
                      value={this.state.rnEmail}
                      onChange={e => {
                        this.setState({ rnEmail: e.target.value })
                      }}
                      placeholder="Your email *"
                      required
                    />
                  </label>

                  <label htmlFor="item03">
                    <input
                      type="text"
                      name="subject"
                      id="item03"
                      value={this.state.rnSubject}
                      onChange={e => {
                        this.setState({ rnSubject: e.target.value })
                      }}
                      placeholder="Write a Subject"
                      required
                    />
                  </label>
                  <label htmlFor="item04">
                    <textarea
                      type="text"
                      id="item04"
                      name="message"
                      value={this.state.rnMessage}
                      onChange={e => {
                        this.setState({ rnMessage: e.target.value })
                      }}
                      placeholder="Your Message"
                      required
                    />
                  </label>
                  <button
                    className="rn-button-style--2 btn-solid"
                    type={this.isValid() ? "submit" : undefined}
                    value="submit"
                    name="submit"
                    id="mc-embedded-subscribe"
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2">
              <div className="thumbnail mb_md--30 mb_sm--30">
                <img src={About.Hello} alt="contact us" />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default ContactForm
