import React from "react"
import TextLoop from "react-text-loop"
import { FiHeadphones, FiMail, FiMapPin } from "react-icons/fi"
// import { Link } from "gatsby"
// import { Parallax } from "react-parallax"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"

import Slider from "../components/Slider"
import WhoAreWe from "../components/elements/WhoAreWe"
import AboutHeader from "../components/elements/AboutHeader"
import ContactForm from "../components/elements/ContactForm"
import AboutVisionMission from "../components/elements/AboutVisionMission"
import Services from "../components/Services"
import Breadcrumb from "../components/Breadcrumb"
import TabOne from "../components/Tabs/TabOne"

import { Background } from "../config/assets"

const CONTACT_DETAILS = {
  PHONE: {
    primary: "+971 58 992 4356",
    secondary: "+971 26 279 480",
  },
  EMAIL: {
    primary: "contact@tekmens.com",
    secondary: "tekmens.official@gmail.com",
  },
  LOCATION: {
    line1: "SHAMS, Al Bataeh",
    line2: "Sharjah, UAE. 56901",
  },
}

const IndexPage = () => (
  <Layout>
    <SEO title="Contact Us" />

    {/* Start Breadcrump Area */}
    <div
      className="rn-page-title-area pt--120 pb--190 bg_image bg_image--contact-bg"
      data-black-overlay="6"
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="rn-page-title text-center pt--100">
              <h2 className="title theme-gradient">Contact With Us</h2>
              <p>
                We, TEKMENS are committed to serving your needs, respond to your
                questions in a timely manner
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* End Breadcrump Area */}

    {/* Start Contact Top Area  */}
    <div className="rn-contact-top-area ptb--120 bg_color--5">
      <div className="container">
        <div className="row">
          {/* Start Single Address  */}
          <div className="col-lg-4 col-md-6 col-sm-6 col-12">
            <div className="rn-address">
              <div className="icon">
                <FiHeadphones />
              </div>
              <div className="inner">
                <h4 className="title">Contact With Phone Number</h4>
                <p>
                  <a href={`tel:${CONTACT_DETAILS.PHONE.primary}`}>
                    {CONTACT_DETAILS.PHONE.primary}
                  </a>
                </p>
                <p>
                  <a href={`tel:${CONTACT_DETAILS.PHONE.secondary}`}>
                    {CONTACT_DETAILS.PHONE.secondary}
                  </a>
                </p>
              </div>
            </div>
          </div>
          {/* End Single Address  */}

          {/* Start Single Address  */}
          <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
            <div className="rn-address">
              <div className="icon">
                <FiMail />
              </div>
              <div className="inner">
                <h4 className="title">Email Address</h4>
                <p>
                  <a href={`mailto:${CONTACT_DETAILS.EMAIL.primary}`}>
                    {CONTACT_DETAILS.EMAIL.primary}
                  </a>
                </p>
                <p>
                  <a href={`mailto:${CONTACT_DETAILS.EMAIL.secondary}`}>
                    {CONTACT_DETAILS.EMAIL.secondary}
                  </a>
                </p>
              </div>
            </div>
          </div>
          {/* End Single Address  */}

          {/* Start Single Address  */}
          <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
            <div className="rn-address">
              <div className="icon">
                <FiMapPin />
              </div>
              <div className="inner">
                <h4 className="title">Location</h4>
                <p>
                  {CONTACT_DETAILS.LOCATION.line1} <br />
                  {CONTACT_DETAILS.LOCATION.line2}
                </p>
              </div>
            </div>
          </div>
          {/* End Single Address  */}
        </div>
      </div>
    </div>
    {/* End Contact Top Area  */}

    {/* Start Contact Page Area  */}
    <div className="rn-contact-page ptb--120 bg_color--1">
      <ContactForm />
    </div>
    {/* End Contact Page Area  */}
  </Layout>
)

export default IndexPage
